<template>
  <!-- 培训实施推送报名 -->
  <div class="container trainImpReqCheck">
    <van-nav-bar title="培训实施报名"
                 :fixed="true"> </van-nav-bar>
    <div class="toExaminedWjuan"
         v-if="formData.isbm"
         style="position: absolute; right: 15px; top: 70px;z-index: 99999;">
      <img style="width: 75px"
           src="@assets/img/yijbml.png" />
    </div>
    <div class="contant_box">
      <div class="gruops">
        <van-field v-model="formData.listno"
                   readonly
                   rows="1"
                   autosize
                   type="textarea"
                   label="实施单号" />
        <van-field v-model="formData.coursename"
                   readonly
                   rows="1"
                   autosize
                   type="textarea"
                   label="课程名称" />
        <van-field v-model="formData.planbt"
                   readonly
                   rows="1"
                   autosize
                   type="textarea"
                   label="培训开始时间" />
        <van-field v-model="formData.planet"
                   readonly
                   rows="1"
                   autosize
                   type="textarea"
                   label="培训结束时间" />
      </div>
      <div class="gruops">
        <van-field v-model="formData.traintype"
                   readonly
                   rows="1"
                   autosize
                   type="textarea"
                   label="培训类别" />
        <van-field v-model="formData.trainstyle"
                   readonly
                   rows="1"
                   autosize
                   type="textarea"
                   label="培训形式" />
        <van-field v-model="formData.teacher_empname"
                   readonly
                   rows="1"
                   autosize
                   type="textarea"
                   label="培训讲师" />
        <van-field v-model="formData.trainplace"
                   readonly
                   rows="1"
                   autosize
                   type="textarea"
                   label="培训地点" />
      </div>
      <div class="gruops">
        <van-field v-model="formData.bmbt"
                   readonly
                   rows="1"
                   autosize
                   type="textarea"
                   label="报名开始日" />
        <van-field v-model="formData.bmet"
                   readonly
                   rows="1"
                   autosize
                   type="textarea"
                   label="报名截止日" />
        <van-field v-model="formData.traintarget"
                   readonly
                   rows="1"
                   autosize
                   type="textarea"
                   label="培训目的" />
      </div>
    </div>
    <div class="footer">
      <div class="f_btn1"
           @click="backto">
        <div>返回</div>
      </div>
      <div v-if="!formData.isbm"
           class="f_btn2"
           @click="enroll">
        <div>报名</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTrainImplReqInfo, trainImplReqCheck } from "@api/wxpx.js";
import { Toast, Dialog } from "vant";
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
export default {
  data () {
    return {
      userInfo,
      formData: {}
    }
  },
  created () {
    this.getData();
  },
  methods: {
    // 获取实施详情
    getData () {
      let autoid = this.$route.query.autoid;
      let username = this.userInfo.username;
      getTrainImplReqInfo({
        autoid: autoid,
        username: username
      }).then(res => {
        this.formData = res.data[0]
      })
    },
    // 报名
    enroll () {
      Dialog.confirm({
        title: "确定要报名吗？",
      }).then(() => {
        let autoid = this.$route.query.autoid;
        let username = this.userInfo.username;
        trainImplReqCheck({
          autoid: autoid,
          username: username
        }).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info);
          } else
          {
            Toast.success("报名成功！");
            setTimeout(() => {
              this.$router.go(0);
            }, 300)
          }
        })
      }).catch(() => { });
    },
    // 返回
    backto () {
      Dialog.confirm({
        title: "确定返回吗？",
      }).then(() => {
        window.opener = null;
        window.open("", "_self");
        window.close();
        WeixinJSBridge.invoke("closeWindow", {}, function (res) { });
      }).catch(() => { });
    },
  }
}
</script>

<style lang="less" scoped>
.trainImpReqCheck {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  .van-nav-bar {
    height: 92px;
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .contant_box {
    height: calc(100% - 212px);
    margin-top: 92px;
    overflow-y: auto;
    .gruops {
      margin: 20px;
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(82, 155, 178, 0.16);
      border-radius: 16px;
      /deep/.van-cell {
        .van-cell__title {
          font-size: 28px;
          color: #999999;
        }
        .van-cell__value {
          font-size: 28px;
          color: #333333;
        }
      }
    }
  }
  .footer {
    height: 120px;
    background: #fff;
    width: 100%;
    position: absolute;
    bottom: 0;
    font-size: 32px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .f_btn1 {
      height: 80px;
      line-height: 80px;
      width: 30%;
      text-align: center;
      background: #cccccc;
      border-radius: 12px;
    }
    .f_btn2 {
      height: 80px;
      line-height: 80px;
      width: 60%;
      text-align: center;
      background: #0b82f8;
      border-radius: 12px;
    }
  }
}
</style>